import { CometChat } from '@cometchat-pro/chat'
import { PropsWithChildren } from 'react';
import { style } from './style';

interface Props extends PropsWithChildren {
  otherUser: CometChat.User | null;
}

export const CometChatCallActions = ({ children, otherUser }: Props) => {
  return (
    <div style={style.container}>
      <div style={style.heading}>{otherUser?.getName()}</div>
      <div style={style.actions}>
        {children}
      </div>
    </div>
  );
};

