/*
  * CometChatAwaitCall component
  * This component is used to display the call screen when a call is received or made.
  * Created for OME Lite, component is based on CometChatMessage component.
*/

import React from "react";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";

import {
  CometChatContextProvider,
  CometChatContext,
} from "../../../util/CometChatContext";
import * as enums from "../../../util/enums.js";
import Translator from "../../../resources/localization/translator";

import { CometChatCallActions } from "../CometChatCallActions";
import { CometChatAnswerDeclineCall } from "./answerDecline";

class CometChatAnswerDeclineCallWrapper extends React.PureComponent {
  static contextType = CometChatContext;

  constructor(props) {
    super(props);

    CometChat.getLoggedinUser()
      .then((user) => (this.loggedInUser = user))
      .catch(() => this.errorHandler("SOMETHING_WRONG"));

    CometChat.getUser(`${this.props.chatWithUser}`)
      .then((otherUser) => {
        this.setState({ otherUser });
      })
      .catch(() => this.errorHandler("SOMETHING_WRONG"));

    this.state = {
      enableCallActionMessages: false,
    };

    this.contextProviderRef = React.createRef();
    this.composerRef = React.createRef();
    this.messageListRef = React.createRef();
    this.outgoingCallRef = React.createRef();
    this.outgoingDirectCallRef = React.createRef();
    this.toastRef = React.createRef();

    this.reactionName = "heart";
  }

  componentDidMount() {
    this.type = this.getContext().type;
    this.item = this.getContext().item;

    this.enableCallActionMessages();
  }

  componentDidUpdate(prevProps, prevState) {
    if (Object.keys(this.item).length) {
      const ifChatWindowChanged = () => {
        let output = false;

        if (
          this.getContext().type === CometChat.ACTION_TYPE.TYPE_USER &&
          (this.getContext().item.uid !== this.item.uid ||
            this.getContext().item.blockedByMe !== this.item.blockedByMe)
        ) {
          output = true;
        } else if (this.type !== this.getContext().type) {
          output = true;
        }

        return output;
      };

      if (ifChatWindowChanged() === true) {
        this.setState({
          messageList: [],
          scrollToBottom: true,
          messageToBeEdited: "",
          threadmessageview: false,
          viewdetailscreen: false,
          unreadMessages: [],
        });
      }
    }

    this.type = this.getContext().type;
    this.item =
      this.getContext().type === CometChat.ACTION_TYPE.TYPE_USER ||
        CometChat.ACTION_TYPE.TYPE_GROUP
        ? this.getContext().item
        : {};

  };

  enableCallActionMessages = () => {
    this.getContext()
      .FeatureRestriction.isCallActionMessagesEnabled()
      .then((response) => {
        /**
         * Don't update state if the response has the same value
         */
        if (response !== this.state.enableCallActionMessages) {
          this.setState({ enableCallActionMessages: response });
        }
      })
      .catch((error) => {
        if (this.state.enableCallActionMessages !== false) {
          this.setState({ enableCallActionMessages: false });
        }
      });
  };

  getContext = () => {
    if (this.props._parent.length) {
      return this.context;
    } else {
      return this.contextProviderRef.state;
    }
  };

  // OML: Adjusted to the needs of the app
  actionHandler = (action, messages, key) => {
    switch (action) {
      case enums.INCOMING_CALL_RECEIVED:
      case enums.OUTGOING_CALL_ACCEPTED:
        break;
      case enums.INCOMING_CALL_CANCELLED:
      case enums.OUTGOING_CALL_REJECTED:
      case enums.ACTIONS.CALL_ENDED:
      case enums.ACTIONS.USER_LEFT_CALL:
      case enums.ACTIONS.OUTGOING_CALL_ENDED:
      case enums.ACTIONS.OUTGOING_CALL_REJECTED:
      case enums.ACTIONS.OUTGOING_CALL_CANCELLED:
      case enums.ACTIONS.INCOMING_CALL_ENDED:
      case enums.ACTIONS.INCOMING_CALL_REJECTED:
      case enums.ACTIONS.DIRECT_CALL_ENDED:
      case enums.ACTIONS.DIRECT_CALL_ERROR:
        window.flutter_inappwebview.callHandler('chatLink', 'call-ended');
        break;
      case enums.ACTIONS["ERROR"]:
        this.errorHandler(key);
        break;
      default:
        console.log(action, messages);
        break;
    }
  };

  errorHandler = (errorCode) => {
    if (typeof this.toastRef.setError === "function") {
      this.toastRef?.setError(errorCode);
    }
  };

  getReceiverDetails = () => {
    let receiverId;
    let receiverType;

    if (this.getContext().type === CometChat.ACTION_TYPE.TYPE_USER) {
      receiverId = this.getContext().item.uid;
      receiverType = CometChat.RECEIVER_TYPE.USER;
    } else if (this.getContext().type === CometChat.ACTION_TYPE.TYPE_GROUP) {
      receiverId = this.getContext().item.guid;
      receiverType = CometChat.RECEIVER_TYPE.GROUP;
    }

    return { receiverId: receiverId, receiverType: receiverType };
  };

  render() {
    if (this.props.chatWithUser.trim().length === 0) {
      return null;
    }

    return (
      <CometChatContextProvider
        ref={(el) => (this.contextProviderRef = el)}
        user={this.props.chatWithUser}
        group={this.props.chatWithGroup}
        language={Translator.getDefaultLanguage()}
      >
        <CometChatCallActions otherUser={this.state.otherUser}>
          <CometChatAnswerDeclineCall
            theme={this.props.theme}
            lang={this.props.lang}
            actionGenerated={this.actionHandler}
            action={this.props.action}
          />
        </CometChatCallActions>
      </CometChatContextProvider>
    );
  }
}

CometChatAnswerDeclineCallWrapper.defaultProps = {
  _parent: "",
  widgetsettings: {},
  chatWithUser: "",
  action: "answer",
};

CometChatAnswerDeclineCallWrapper.propTypes = {
  _parent: PropTypes.string,
  widgetsettings: PropTypes.object,
  chatWithUser: PropTypes.string,
  action: PropTypes.string,
};

export { CometChatAnswerDeclineCallWrapper };
