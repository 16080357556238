import { useEffect } from 'react';
import { homepageUrl } from './config';

export const Home = () => {
  useEffect(() => {
    window.location.replace(homepageUrl);
  }, []);

  return <></>;
};
