export const style = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1rem',
    with: '100%',
    height: '100vh',
  },
  heading: {
    fontSize: '3rem',
    fontWeight: 600,
    padding: '0 3rem',
    margin: '5rem 0 2rem 0',
    textAlign: 'center' as 'center',
  },
  actions: {
    width: '100%',
  }
};
