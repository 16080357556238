import ReactDOM from 'react-dom';
import App from './defaultPages/App';

import * as serviceWorker from './serviceWorker';
import { CometChat } from "@cometchat-pro/chat"
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import './index.scss';
import { cometChatConfig } from './config';
import { Home } from './home';
import AnswerDecline from './defaultPages/AnswerDecline';

declare global {
  interface Window { flutter_inappwebview: any; }
}

var appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(cometChatConfig.region)
  .build();

CometChat.init(cometChatConfig.appID, appSetting)
  .then(() => {
    if (CometChat.setSource) {
      CometChat.setSource("ui-kit", "web", "reactjs");
    }

    ReactDOM.render(
      <BrowserRouter>
        <Switch>
          <Route path="/answer/:loggedInUserId/:otherUserId/:callSessionId">
            <AnswerDecline action="answer" />
          </Route>
          <Route path="/decline/:loggedInUserId/:otherUserId/:callSessionId">
            <AnswerDecline action="decline" />
          </Route>
          <Route path="/:loggedInUserId/:otherUserId/:callSessionId">
            <App />
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>
        </Switch>
      </BrowserRouter>,
      document.getElementById('root')
    );
  })
  .catch((error) => {
    console.log("Initialization failed with error:", error);
    window.flutter_inappwebview.callHandler('chatLink', 'call-ended');
  });

serviceWorker.unregister();
