import { useEffect, useState } from 'react';

import {
    spinnerStyle,
    wrapperStyle
} from "./style";

import { CometChat } from '@cometchat-pro/chat';
import { useParams } from 'react-router-dom';
import { CometChatAwaitCall } from '../../cometchat-pro-react-ui-kit/CometChatWorkspace/src/components/Calls/CometChatAwaitCall';
import { cometChatConfig } from '../../config';

type Params = {
    loggedInUserId: string;
    otherUserId: string;
    callSessionId: string | undefined;
};

const App = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const { loggedInUserId, otherUserId, callSessionId } = useParams<Params>();
    const [hasPermission, setHasPermission] = useState(true);

    const requestPermissions = async () => {
        let stream = null;

        try {
            stream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode: "user" },
                audio: true,
            });
        } catch (error) {
            console.log('Failed to get permissions:', error);
            setHasPermission(false);
        }

        if (stream) {
            stream.getTracks().forEach((track) => track.stop());
            setHasPermission(true);
        }
    };

    useEffect(() => {
        if (!loggedInUserId || !otherUserId || !callSessionId) {
            window.flutter_inappwebview.callHandler('chatLink', 'call-ended');
            return;
        }

        const getLoggedinUser = async () => {
            try {
                let user = await CometChat.getLoggedinUser();

                if (user != null && user?.getUid() !== loggedInUserId) {
                    await CometChat.logout();
                    user = null;
                }

                if (!user) {
                    user = await CometChat.login(loggedInUserId, cometChatConfig.authKey);
                }

                if (!user) {
                    throw new Error('User not logged in');
                }

                await requestPermissions();

                setIsLoaded(true);
            } catch (error) {
                window.flutter_inappwebview.callHandler('chatLink', 'call-ended');
            }
        };

        getLoggedinUser();
    }, [loggedInUserId, otherUserId, callSessionId]);

    const requestPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

            stream.getTracks().forEach((track) => track.stop());

            setHasPermission(true);
        } catch (error) {
            console.log('Failed to get permissions:', error);
        }
    };

    if (!hasPermission) {
        return (
            <div style={wrapperStyle()}>
                <div style={{ textAlign: 'center', margin: '5rem 0' }}>
                    <h1>Permission Required</h1>
                    <p>Please allow camera and microphone access to continue</p>
                    <button onClick={requestPermission}>Allow</button>
                </div>
            </div>
        );
    }

    if (!isLoaded) {
        return (
            <div style={spinnerStyle() as React.CSSProperties}>
                <div className="spinner"></div>
            </div>
        );
    }

    return (<CometChatAwaitCall chatWithUser={otherUserId} />);
}

export default App;
