export const wrapperStyle = () => ({
    width: "100vw",
    height: "100vh",
});

export const spinnerStyle = () => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
});

